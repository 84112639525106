import * as React from "react"

import Button from '@elements/Button';
import { graphql, navigate } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';

import '../styles/pages/404.css';

type NotFoundPageProps = {
  data: {
    file: {
      name: string,
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData,
      },
    },
  },
  location: {
    state: {
      key: string,
      prevPath: string,
    }
  }
};

const NotFoundPage = ({
  data,
  location,
}: NotFoundPageProps): JSX.Element => {
  const image = getImage(data.file.childImageSharp?.gatsbyImageData);

  const handleRedirect = () => {
    const backwardPath: string | any = location.state?.prevPath ? -1 : "/";
    navigate(backwardPath);
  };

  return (
    <div className="error-page--main">
      <div className="error-page__inner">
        <figure className="error-page__image-wrapper">
          <GatsbyImage alt={data.file.name} image={image} />
        </figure>
        <p className="error-page--line" />
        <h1><strong>Page Not Found</strong></h1>
        <p>The page you are looking for cannot be found</p>
        <Button
          childClassName="btn--secondary px-16 mt-4"
          onClick={handleRedirect}
        >
          Go Back
        </Button>
      </div>
    </div>
  );
};

export default NotFoundPage;

export const queryIndex = graphql`
  query QUERY_404_ERROR {
    navigation: contentfulNavigationNavMenu(
      contentful_id: { eq: "28QWR4Rx641pUxsQaVNB1m" }
    ) {
      ...NavMenu
    }
    file(name: { eq: "error404" }) {
      id
      name
      childImageSharp {
        gatsbyImageData(width: 650, placeholder: BLURRED, quality: 90)
      }
      publicURL
    }
  }
`;
